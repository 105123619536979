<script>
import ui from '/~/core/ui'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLink from '/~/components/base/link/base-link.vue'

export default {
  name: 'bill-payments-message-panel',
  components: {
    BaseLink,
    BaseButton,
    BaseIcon,
  },
  props: {
    type: {
      type: String,
      default: 'primary',
      validator: (v) => !v || /primary|warning|error/.test(v),
    },
    link: {
      type: Object,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    dontClose: {
      type: Boolean,
      default: false,
    },
    iconSize: {
      type: Number,
      default: 24,
    },
  },
  setup() {
    return {
      ui,
    }
  },
  computed: {
    colorClasses() {
      switch (this.type) {
        case 'warning':
          return {
            bg: 'bg-orange-50 border border-orange-700',
            text: '!text-orange-700 border-orange-700',
            icon: 'text-orange-700',
          }
        case 'error':
          return {
            bg: 'bg-red-50 border border-red-700',
            text: 'text-red-700',
            icon: 'text-red-700',
          }
        case 'primary':
          return {
            bg: 'bg-primary-100 border',
            text: 'text-primary',
            icon: 'text-eonx-neutral-600',
          }
        default:
          return {
            bg: 'bg-primary border',
            text: 'text-eonx-neutral-600',
            icon: 'text-eonx-neutral-600',
          }
      }
    },
  },
  methods: {
    click() {
      if (this.dontClose) return
      this.$emit('close')
    },
  },
}
</script>

<template>
  <transition>
    <div
      class="relative flex items-center px-3 py-1.5"
      :class="[colorClasses.bg, { rounded: !ui.mobile }]"
    >
      <base-icon
        v-if="icon"
        :svg="icon"
        :size="iconSize"
        class="flex-none"
        :class="colorClasses.icon"
      />
      <div class="ml-3 grow space-y-2.5" :class="colorClasses.text">
        <div v-if="$slots.title" class="text-lg font-bold">
          <slot name="title" />
        </div>
        <div v-if="$slots.default" class="text-normal mr-5">
          <slot />
        </div>
      </div>
      <base-link
        v-if="link && link.enabled"
        v-bind="link"
        class="!hover:opacity-50 flex items-center border-b"
        :class="colorClasses.text"
      >
        <span>{{ link.title }}</span>
      </base-link>
      <base-button
        v-if="!dontClose"
        type="icon"
        :size="24"
        icon="plain/close"
        class="flex-none"
        :class="colorClasses.icon"
        @click="click"
      />
    </div>
  </transition>
</template>
